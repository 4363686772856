@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .navbar-icon {
        @apply relative flex items-center justify-center h-14 w-14 my-auto mx-2
        hover:bg-slate-700 hover:text-slate-200 rounded-3xl hover:rounded-xl
        transition-all;
    }
    .project-button {
        @apply hover:translate-x-4 hover:font-bold transition-all duration-300
    }
    .menu-hover {
        @apply hover:translate-x-8 hover:scale-110 transition-all duration-400
    }
    .animate-section {
        @apply opacity-0 scale-x-90 -translate-x-8 transition-all duration-200
    }
    .animate-section.in.visible {
        @apply opacity-100 scale-x-100 translate-x-0
    }
}